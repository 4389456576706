import React, {useState} from 'react';
import Dropdown from '../../../UiComponents/Dropdown';


const venueItems = [
    { label: 'Other' },
    { label: 'Restaurant' },
    { label: 'Bar' },
    { label: 'Cafe' },
    { label: 'Hotel' },
    { label: 'Entertainment Venue' },
    { label: 'Retail' },
    { label: 'Demo Venue' },
];


const VenueTypeDropdown = ({venue, saveHandler}) => {

    const [selectedItem, setSelectedItem] = useState(venueItems.find(item => item.label === venue?.type) || venueItems[0]);

    const onSelectHandler = (item) => {
        setSelectedItem(item);
        saveHandler({type: item.label});
    }

    return (
        <div>
            <label>Venue Type</label>
            <Dropdown
                placeholder={'Select Venue Type'}
                items={venueItems}
                selectedItem={selectedItem}
                clearable={false}
                onClick={(item) => onSelectHandler(item)}
                style={{ width: '100%' }}
                dropdownStyle={{ width: '100%' }}
            />


        </div>
    )
}

export default VenueTypeDropdown;