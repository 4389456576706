import styled from "styled-components";
import theme from "../UI/theme";
import ViewHeader from "../components/UiComponents/ViewHeader";
import { SettingsParagraph, SettingsSection } from "../components/settings/settingsStyles";
import InputField from "../components/UiComponents/InputField";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { ButtonRowContainer } from "../components/panel/ModalStyles";
import Button from "../components/UiComponents/Button";
import { useState } from "react";
import { signUpUser } from "../scripts/user";
import { loadingStates } from "../components/installation/installationTypes";
import { loginUser } from "../scripts/authentication";
import Checkbox from "../components/UiComponents/Checkbox";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    background-color: ${theme.colors.darkSpace90};
    padding: 20px;
    box-sizing: border-box;
`

const LicenseSection = styled.div`
    display: flex;
    flex-direction: row;
    
    p {
        color: ${theme.colors.whiteSpace};
        font-size: 12px;
    }
`

const SignUpView = props => {
    const { signUpRequestId } = useParams();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [title, setTitle] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [error, setError] = useState();
    const [allowRetry, setAllowRetry] = useState(false);
    const [licenseAgreement, setLicenseAgreement] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState('');
    const [licenseAgreementMessage, setLicenseAgreementMessage] = useState('');
    const history = useHistory();

    const handleSignUp = async () => {

        if (password !== passwordConfirm) {
            setPasswordMessage('Passwords do not match.');
            return;
        } 
        if (!licenseAgreement) {
            setLicenseAgreementMessage('You must agree to the license agreement.');
            return;
        }

        try {
            setLoadingState(loadingStates.LOADING);
            const userObject = {
                firstName: firstName,
                lastName: lastName,
                title: title,
                phone: phone,
                password: password,
            };

            await signUpUser(userObject, signUpRequestId);

            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            setLoadingState(loadingStates.FAILED);
            if (error.response?.status === 410) {
                setError('Invalid or expired sign-up session. Please ask the venue manager of your venue to create a new sign-up request.')
            } else {
                setError('Sign-up failed.');
            }
        }
    }

    const handleRetry = () => {
        setError('');
        setAllowRetry(false);
        setLoadingState(loadingStates.NONE);
    }

    const handleSignIn = async () => {
        history.push('/');
        window.location.reload();
    }

    return <>
        <ViewHeader headerText={'Sign Up'} />

        <Container>
            {loadingState === loadingStates.NONE ? <>
                <SettingsSection>
                    <SettingsParagraph>First name *</SettingsParagraph>
                    <InputField value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Last name *</SettingsParagraph>
                    <InputField value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Job title *</SettingsParagraph>
                    <InputField value={title} onChange={(e) => setTitle(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Phone *</SettingsParagraph>
                    <InputField value={phone} onChange={(e) => setPhone(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Password *</SettingsParagraph>
                    <InputField value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Retype password *</SettingsParagraph>
                    <InputField value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} type="password" />
                </SettingsSection>

                <SettingsSection style={{minHeight: '20px'}}>
                    {passwordMessage && !(password === passwordConfirm)? <p style={{color: theme.colors.raspberryRed, fontSize: '12px', margin: '0px'}}>{passwordMessage}</p> : null}
                    {licenseAgreementMessage && !licenseAgreement? <p style={{color: theme.colors.raspberryRed, fontSize: '12px', margin: '0px'}}>{licenseAgreementMessage}</p> : null}
                </SettingsSection>

                <SettingsSection>
                    <a style={{ color: theme.colors.whiteSpace, fontSize: '12px' }} href='https://waved.co/license-agreement' target="_blank" rel="noreferrer">License aggrement</a>
                    <LicenseSection>
                        <Checkbox
                            style={{ margin: 'auto', marginRight: '10px' }}
                            checked={licenseAgreement}
                            onChange={() => setLicenseAgreement(!licenseAgreement)}
                        />
                        <p>I have read and agree to the license agreement *</p>
                    </LicenseSection>
                </SettingsSection>

                <SettingsSection>
                    <ButtonRowContainer>
                        <Button
                            disabled={
                                firstName?.length < 1 ||
                                lastName?.length < 1 ||
                                password?.length < 1 ||
                                title?.length < 1 ||
                                phone?.length < 1 ||
                                passwordConfirm?.length < 1
                            }
                            onClick={() => handleSignUp()}
                            primary>
                            Sign Up
                        </Button>
                    </ButtonRowContainer>
                </SettingsSection>
            </> : <></>}

            {loadingState === loadingStates.LOADING ? <>
                <SettingsParagraph>Signing up...</SettingsParagraph>
            </> : <></>}

            {loadingState === loadingStates.SUCCESS ? <>
                <SettingsSection>
                    <SettingsParagraph>Sign-up successful.</SettingsParagraph>
                </SettingsSection>

                <SettingsSection>
                    <ButtonRowContainer>
                        <Button primary onClick={() => handleSignIn()}>Go to sign-in</Button>
                    </ButtonRowContainer>
                </SettingsSection>
            </> : <></>}

            {loadingState === loadingStates.FAILED ? <>
                <SettingsSection>
                    <SettingsParagraph>{error}</SettingsParagraph>
                </SettingsSection>

                {allowRetry ? <>
                    <SettingsSection>
                        <ButtonRowContainer>
                            <Button primary onClick={() => handleRetry()}>Retry</Button>
                        </ButtonRowContainer>
                    </SettingsSection>
                </> : <></>}

            </> : <></>}
        </Container>
    </>
}

export default SignUpView;